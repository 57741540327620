.CheckListGroup {
	display: flex;
	flex-direction: column;
}

.CheckListGroupHeader {
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
}

.CheckListGroupLabel {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #738094;
}

.CheckListGroupLabelScore {
	margin-left: 8px;
}

.CheckListGroupBody {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.ChekListAppraised {
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
	color: #738094;
}
